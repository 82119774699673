<template>
  <div>
    hello i switch
  </div>
</template>

<script>
export default {
  name: "eva-switch-field",
  dbType: 'bool'

}
</script>

<style lang="less" scoped></style>