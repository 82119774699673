
    import localesHash from '@eva/client/plugins/locales/LocalesHash';
    export default function (Component) {
      localesHash.push({ 
        lang: 'ru', 
        data: {
          [Component.options.name]: 
{
  caption: 'Слои',
  columns: {
    name: {
      header: 'Название'
    },
  },
  commands: {
    add: {
      header: 'Создать слой',
      success: 'Слой создан успешно',
      error: 'При создании слоя произошла ошибка'
    },
    edit: {
      header: 'Редактировать слой',
      success: 'Слой отредактирован успешно',
      error: 'При редактировании слоя произошла ошибка'
    },
    remove: {
      header: 'Удалить слой',
      confirm: 'Удалить слой?',
      success: 'Слой удален успешно',
      error: 'При удалении слоя произошла ошибка'
    },
    clearLayer: {
      header: 'Сбросить слой',
      success: 'У выбранных объектов слой успешно сброшен',
      error: 'При сбросе слоя у выбранных объектов произошла ошибка',
      confirm: 'Сбросить слой у выбранных объектов?'
    },
    addLayer: {
      success: 'Выбранным объектам успешно назначен слой',
      error: 'При назначении слоя выбранным объектам произошла ошибка',
      confirm: 'Назначить выбранным объектам слой?'
    }
  }
}

        } 
      });
    }