<template>
  <div class="eva-switch">
    <span v-if="textPosition === 'left' && !!text">{{ text }}</span>
    <div class="switch-rectangle" :class="{ 'active': value }" @click="toggleSwitch">
      <div class="switch-toggle" :class="{ 'active': value }"></div>
    </div>
    <span v-if="textPosition === 'right' && !!text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'eva-switch',

  props: {
    text: {
      type: String,
      default: "Switch"
    },
    textPosition: {
      type: String,
      default: 'left'
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    toggleSwitch() {
      this.$emit('input', !this.value);
    },
  },

}
</script>

<style lang="less" scoped>
.eva-switch {
  display: flex;
  column-gap: 10px;
  user-select: none;

  .switch-rectangle {
    width: 42px;
    height: 24px;
    border-radius: 12px;
    border: 1.5px;
    background: #7B8B9D;
    transition: background 0.3s;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 3px;

    &.active {
      background: #0094FF;
      transition: background 0.3s;
    }

    .switch-toggle {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background: #E4E9EF;
      transition: transform 0.3s;

      &.active {
        transform: translateX(18px);
      }
    }
  }
}
</style>