<template>
  <img ref="preview-image" @click="hide"/>
</template>

<script>
export default {
  name: 'eva-file-attach-field-preview-image-dialog',

  props: {
    image: { 
      type: [File, Blob],
      required: true,
    },
    box: {
      type: Object,
    },
  },
  
  mounted() {
    let fr = new FileReader();
    //console.log(this.$refs);
    const img = this.$refs['preview-image'];
    fr.onload = function () {
      img.src = fr.result; 
    }
    fr.readAsDataURL(this.image);
  },

  methods:{
    hide(){
      this.box.hide('cancel');
    }
  }
}
</script>