<template>
  <div
    class="eva-catalog-ref-field"
    style="position: relative;"
    v-show="!isHide"
  >
    <v-menu
      :close-on-content-click="false"
      v-model="shownMenu"
      :disabled="readOnly || preview"
      offset-y
      :nudge-top="settings && settings.offsetY"
      :nudge-left="settings && settings.offsetX"
      :content-class="'eva-catalog-ref-field ' + menuClass"
      z-index="10000"
      :max-width="maxWidth"
      :transition="false"
      ref="menu"
    >
      <template v-slot:activator="{ on, attrs, value }">
        <eva-input
          :value="getValue"
          :label="fieldLabel"
          :error="fieldErrors"
          :title="fieldTitle"
          :placeholder="fieldPlaceholder"
          :readonly="readOnly"
          :preview="preview"
          :depth="depth"
          :icon="(readOnly || preview) ? null : value ? 'mdi-menu-up' : 'mdi-menu-down'"
          v-bind="attrs"
          v-on="on"
          :clearable="isClearable"
          @icon-click="on.click"
          @clear="clearModel"
          @click="$emit('click', $event)"
        >
          <div class="eva-textbox__input d-flex align-center">
            <div
              v-if="!multiple"
            >
              <template v-if="settings.selectionItemComponent">
                <component :is="settings.selectionItemComponent" :item="modelValue"/>
              </template>
              <template v-else>
                <v-icon
                  v-if="modelValue && modelValue.icon"
                  :size="16"
                  :color="getIconColor(modelValue.icon, '#000000')"
                >
                  {{ modelValue.icon.name }}
                </v-icon>
                <div
                  :class="{'eva-catalog-ref__list-item__text-icon': modelValue && modelValue.icon}"
                  :style="getItemStyle(modelValue)"
                >
                  {{ getItemName(modelValue) }}
                </div>
              </template>
            </div>
            <div
                v-else-if="modelValue" class="eva-textbox__input-inner"
                :class="{
                  'eva-textbox__input-inner--no-wrap': !!noWrap,
                  'eva-textbox__input-inner--column': !!isColumn
                 }"
            >
              <draggable v-model="modelValue">
                <transition-group class="eva-catalog-ref-field__list">
                  <v-chip
                      v-for="item in modelValue"
                      :key="item.id || item"
                      :close="!readOnly && !preview"
                      @click:close="deselectItem(item)"
                      :class=getChipClasses(item)
                      close-icon="mdi-close"
                  >
                    <!-- TODO: доработать изменение цвета под тему приложения -->
                    <v-icon
                        v-if="item.icon"
                        :size="16"
                        :color="getIconColor(item.icon, '#FFFFFF')"
                    >
                      {{ item.icon.name }}
                    </v-icon>
                    <eva-data-name :value="getItemName(item)" :clipped="false" />
                    <eva-spacer/>
                    <v-icon
                        v-if="actions && actions.view"
                        @click.stop="viewItem(item)"
                        class="v-chip__close"
                        v-tooltip="$eva.$tools.tooltipContent($eva.$t(`$t.${formSettings.prefix}.${settings.name}.actions.view.title`))"
                    >
                      mdi-eye
                    </v-icon>
                    <v-icon
                        v-if="actions && actions.edit && !readOnly"
                        @click.stop="editItem(item)"
                        class="v-chip__close"
                        v-tooltip="$eva.$tools.tooltipContent($eva.$t(`$t.${formSettings.prefix}.${settings.name}.actions.edit.title`))"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                        v-for="action in getAdditionalActions(item)"
                        :key="action.id"
                        :color="action.color"
                        @click.stop="action.handle(item)"
                        class="v-chip__close"
                        v-tooltip="$eva.$tools.tooltipContent($eva.$t(action.tooltip))"
                    >
                      {{ action.icon }}
                    </v-icon>
                    <v-icon
                        v-if="actions && actions.remove"
                        @click.stop="removeItem(item)"
                        class="v-chip__close"
                        v-tooltip="$eva.$tools.tooltipContent($eva.$t(`$t.${formSettings.prefix}.${settings.name}.actions.remove.title`))"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </v-chip>
                </transition-group>
              </draggable>
            </div>
          </div>
        </eva-input>
      </template>
      <eva-layout column max-height="350px">
        <eva-layout
          v-if="showSearch"
          column class="eva-catalog-ref-search"
        >
          <eva-textbox
            v-model="search"
            placeholder="Поиск"
            icon="mdi-magnify"
            style="flex-grow: 1"
          />
          <eva-btn
            v-if="actions && actions.add"
            type="icon--secondary"
            icon="mdi-plus"
            @click="createByMeta"
            v-tooltip="$eva.$tools.tooltipContent($eva.$t(`$t.${formSettings.prefix}.${settings.name}.actions.add.title`))"
          />
        </eva-layout>
        <eva-layout column scroll no-gap>
          <div
            v-for="(item, itemIndex) in items"
            :key="item.id"
            :class="getClasses(item, itemIndex)"
            @click="selectItem(item)"
          >
            <template v-if="settings.listItemComponent">
              <component :is="settings.listItemComponent" :item="item"/>
            </template>
            <template v-else>
              <v-icon
                  v-if="item.icon"
                  :size="16"
              >
                {{ item.icon.name }}
              </v-icon>
              <eva-data-name
                  :class="getItemClass(item)"
                  :style="getItemStyle(item)"
                  :value="getItemName(item)"
                  :clipped="false"
              />
            </template>
            <div class="eva-catalog-ref__list-item__actions">
              <v-icon
                v-for="(action, index) in extraActions"
                :key="index"
                @click.stop="action.handler(item)"
              >
                {{ action.icon }}
              </v-icon>
              <div v-if="multiple">
                <v-simple-checkbox
                  :value="isSelectedItem(item)"
                  @input="selectItem(item)"
                />
              </div>
            </div>
          </div>
          <div class="eva-catalog-ref-list-footer">
            <div v-if="shownMenu" v-intersect="onIntersect"></div>
            <div v-if="loadItemsError" style="color: #F44336">
              {{ loadItemsError }}
            </div>
          </div>
        </eva-layout>
        <eva-layout row v-if="multiple" style="flex-shrink: 0; padding: 8px;">
          <eva-spacer/>
          <eva-btn
            type="text--small"
            color="info"
            label="OK"
            @click="confirm"
          />
          <eva-btn
            type="icon--secondary--small"
            icon="mdi-close"
            @click="close"
          />
        </eva-layout>
      </eva-layout>
    </v-menu>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { debounce } from 'lodash';
import getQuery from '../../../../../../../../plugins/core-plugin/client/components/utils/get-query';
import { watch } from "vue";
import { getItemName } from './commonMethods';

const DEBOUNCE = 500;

export default {
  name: 'eva-catalog-ref-field',

  dbType: 'ref',

  components: {
    draggable,
  },

  data () {
    return {
      search: '',
      items: [],
      total: null,
      shownMenu: false,
      loading: true,
      loadItemsError: null,
      copyModelValue: null,
      maxWidth: null,
      menuClass: 'eva-opacity-none'
    }
  },

  computed: {
    showSearch() {
      return this.settings.search !== undefined ? this.settings.search : true;
    },
    fieldName() {
      return this.settings?.name;
    },
    itemsPerPage() {
      return this.settings?.itemsPerPage || 10;
    },
    multiple() {
      return this.settings?.multiple || false;
    },
    actions() {
      return this.settings?.actions;
    },
    noWrap() {
      return this.settings?.noWrap;
    },
    isColumn() {
      return this.settings?.column;
    },
    selectItemsWorkaround() {
      // чтобы корректно выбирались значения
      if (!this.modelValue) {
        return [];
      }
      return this.multiple ? this.modelValue : [this.modelValue];
    },
    extraActions() {
      const actions = [];
      if (this.actions && this.actions.view) {
        actions.push({
          icon: "mdi-eye",
          handler: async (item) => {
            this.shownMenu = false;
            await this.viewItem(item);
          }
        });
      }
      if (this.actions && this.actions.edit) {
        actions.push({
          icon: "mdi-pencil",
          handler: async (item) => {          
            await this.editItem(item);
          }
        });
      }
      if (this.actions && this.actions.remove) {
        actions.push({
          icon: "mdi-trash-can-outline",
          handler: async (item) => {
            await this.removeItem(item)
          }
        });
      }
      return actions;
    },
    getValue() {
      return this.multiple
        ? !!this.modelValue?.length > 0
        : !!this.modelValue;
    },
    isClearable() {
      const isEditable = !this.readOnly && !this.preview;

      return (this.multiple
        ? this.modelValue?.length > 0
        : this.modelValue) && isEditable;
    }
  },

  watch: {
    async search() {
      // 0 для сброса, если стереть поисковую строку
      if (this.search?.length > 0 && this.search?.length < 2) {
        return;
      }
      await this.clearAndSearchItems();
    },
    shownMenu() {
      this.maxWidth = this.$el.clientWidth;
      if (this.shownMenu) {
        this.$nextTick(() => {
          this.$refs.menu.onResize();
          setTimeout(() => this.menuClass = '', 120);
        });
        this.setScrollHandler();
      } else {
        this.menuClass = 'eva-opacity-none';
        this.clearScrollHandler();
      }

      if (this.shownMenu) {
        this.copyModelValue = this.multiple
          ? this.$eva.$tools.clone(this.modelValue)
          : null;
        this.loadItems();
      } else {
        if (this.copyModelValue) {
          this.copyModelValue = null;
        }
        this.items = [];
        this.total = null;
      }
    }
  },

  methods: {
    getItemName,
    getChipClasses(item) {
      let baseClass = 'eva-catalog-ref-field__chip';
      if (this.settings?.extraController?.isError(item)) {
        baseClass += " eva-catalog-ref-field__chip-error";
      }

      return baseClass;
    },
    getAdditionalActions(item) {
      return this.settings?.extraController?.getActions(item) || [];
    },
    getIconColor(iconData, defaultColor) {
      return iconData.color ? iconData.color : defaultColor;
    },
    getClasses(item, index) {
      const classes = ['eva-catalog-ref__list-item'];
      if (this.isSelectedItem(item)) {
        classes.push('eva-background-1');
      }
      return classes;
    },
    getItemClass(item) {
      if (this.items.some((i) => i.icon)) {
        return item && item.icon
          ? 'eva-catalog-ref__list-item__text-icon'
          : 'eva-catalog-ref__list-item__text-no-icon'
      }
    },
    getItemStyle(item) {
      if (item && item.style) {
        return item.style;
      }
      return '';
    },
    isSelectedItem(item) {
      if (
        !this.fieldName
        || (this.multiple && !this.copyModelValue)
        || (!this.multiple && !this.modelValue)
      ) {
        return false;
      }
      if (this.settings.return) {
        if (typeof item[this.settings.return] === "string") {
          let selected = this.modelValue;
          if (this.multiple) {
            selected = this.copyModelValue;
            return (selected || []).length !== 0 && selected.some((s) => s === item[this.settings.return]);
          } else {
            return !!selected && selected === item[this.settings.return];
          }
        }
      } else {
        let selected = this.modelValue;
        if (this.multiple) {
          selected = this.copyModelValue;
          return (selected || []).length !== 0 && selected.some((s) => s.id === item.id);
        } else {
          return !!selected?.id && selected?.id === item.id;
        }
      }
    },
    selectItem(item) {
      const value = this.settings?.return
        ? this.$eva.$tools.getNestedValue(
          item,
          this.settings.return
        ) : item;
      const selected = this.copyModelValue;

      if (selected) {
        const compareFunc = this.settings?.return
          ? (s) => s === value
          : (s) => s?.id === value?.id;

        const hasItem = this.multiple ? (selected).some(compareFunc) : compareFunc(selected);
        
        if (hasItem) {
          this.deselectItem(item);
          return;
        }
      }

      this.copyModelValue = this.multiple ? (selected || []).concat(value) : value;
      
      if (!this.multiple) {
        this.confirm();
      }
    },
    deselectItem(item, isMetaEdit = false) {
      if (this.shownMenu || isMetaEdit) {
        if (this.settings.return) {
          if (typeof item === "string") {
            this.copyModelValue = this.multiple ? (this.copyModelValue).filter((s) => s !== item) : undefined;
          }
        } else {
          this.copyModelValue = this.multiple ? (this.copyModelValue).filter((s) => s.id !== item.id) : undefined;
        }
      } else {
        if (this.settings.return) {
          if (typeof item === "string") {
            this.modelValue = this.multiple ? (this.modelValue).filter((s) => s !== item) : undefined;
          }
        } else {
          this.modelValue = this.multiple ? (this.modelValue).filter((s) => s.id !== item.id) : undefined;
        }
      }
    },
    async viewItem(item) {
      const model = await this.$eva.$http.getItem(`${this.settings?.url}/${item.id}`);
      await this.$eva.$metadatas.openMeta(this.settings.meta, {
        type: 'drawer',
        url: this.settings.url,
        header: `$t.${this.formSettings.prefix}.${this.settings.name}.actions.view.title`,
        settings: { readOnly: true },
        model
      });
    },
    async editItem(item) {
      const modelValue = this.$eva.$tools.clone(
        this.shownMenu 
          ? this.copyModelValue 
          : this.modelValue
      );
      this.shownMenu = false;
      const model = await this.$eva.$http.getItem(`${this.settings?.url}/${item.id}`);   
      await this.$eva.$metadatas.openMeta(this.settings.meta, {
        type: 'drawer',
        url: this.settings.url,
        header: `$t.${this.formSettings.prefix}.${this.settings.name}.actions.edit.title`,
        model,
        ok: async () => {
          try {        
            this.copyModelValue = modelValue; 
            const data = await this.$eva.$http.put(`${this.settings.url}/${model.id}`, model);
            const result = await this.$eva.$http.getList(`${this.settings?.url}?q=_id__eq__${data.id}`);
            if (this.isSelectedItem(result.items[0])) {
              this.deselectItem(result.items[0], true);
              this.selectItem(result.items[0]);
              if (this.multiple) {
                this.confirm();
              }
            }
          } catch (e) {
            this.$eva.$boxes.notifyError(`Error during creation object: ${e.message}`);
            this.$eva.$logs.error(this.$options.name, 'При редактировании произошла ошибка', e);
          }
        }
      })
    },
    async removeItem(item) {
      try {
        await this.$eva.$http.delete(`${this.settings.url}/${item.id}`);
        await this.loadItems(true);
      } catch (e) {
        this.$eva.$boxes.notifyError(`Error during deleting object: ${e.message}`);
        this.$eva.$logs.error(this.$options.name, 'При удалении произошла ошибка', e);
      }
    },
    async createByMeta() {
      const modelValue = this.$eva.$tools.clone(this.copyModelValue)
      this.shownMenu = false;
      let model = { };
      if (this.settings.meta.model) {
        model = {...this.settings.meta.model};
      }    
      if (!this.settings.meta.columns) {
        const meta = await this.$eva.$http.getListFirstItem(`/api/v1/metadata?q=meta_name__eq__${this.settings.meta.name}`);
        model.ref_metadata = {
          id: meta.id
        }
      }
      await this.$eva.$metadatas.openMeta(this.settings.meta, {
        type: 'drawer',
        url: this.settings.url,
        header: `$t.${this.formSettings.prefix}.${this.settings.name}.actions.add.title`,
        isnew: true,
        model,
        ok: async () => {
          try {
            this.copyModelValue = modelValue;
            const data = await this.$eva.$http.post(this.settings.url, model);
            const result = await this.$eva.$http.getList(`${this.settings?.url}?q=_id__eq__${data.id}`);
            this.selectItem(result.items[0]);
            if (this.multiple) {
              this.confirm();
            }
          } catch (e) {
            this.$eva.$boxes.notifyError(`Error during creation object: ${e.message}`);
            this.$eva.$logs.error(this.$options.name, 'При создании произошла ошибка', e);
          }
        }
      })
    },
    clearModel() {
      if (this.multiple) {
        this.modelValue = [];
      } else {
        this.modelValue = null;
      }
    },
    getQ() {
      if (!this.settings?.q) {
        return;
      }
      const q = Array.isArray(this.settings.q) ? this.settings.q : [this.settings.q];
      const result = [];
      for (const qPart of q) {
        if (typeof qPart === 'object') {
          switch (qPart.op) {
            case '__in__':
              let value = typeof qPart.value === 'function'
                ? (qPart.value() || [])
                  .map((v) => `'${v.id}'`)
                  .join(',')
                : ([...(this.getDynamicValue(qPart.value) || [])])
                  .map((v) => `'${v.id}'`)
                  .join(',');
              if (value) {
                result.push(`${qPart.field}${qPart.op}[${value}]`);
              }
              break;
            default:
              if (this.settings.qHandler && typeof this.settings.qHandler === 'function') { //TODO: Возможно стоит отрефакторить и переделать логику
                result.push(this.settings.qHandler(this.getDynamicValue(qPart.value)));
              } else {
                result.push(`${qPart.field}${qPart.op}${this.getDynamicValue(qPart.value)}`);
              }
              break;
          }
          if (typeof qPart.value === 'string' && qPart.value.startsWith('$current')) {
            watch(
                () => this.getDynamicValue(qPart.value),
                () => this.clearModel()
            );
          }
        } else {
          const templates = qPart.match(this.regexDynamicParam);
          if (!templates || templates.length === 0) {
            result.push(qPart);
          } else {
            let resQ = qPart;
            for (const t of templates) {
              const value = this.getDynamicValue(t.slice(1, -1));
              resQ = resQ.replace(t, value);
            }
            result.push(resQ);
          }
        }
      }
      return result;
    },
    async loadItems(clear) {
      if (!clear && this.total === this.items.length) {
        return;
      }
      this.loading = true;
      this.loadItemsError = null;
      try {
        const url = this.settings?.url;
        if (!url) {
          throw new Error('Wrong settings: url');
        }
        const params = {
          limit: this.itemsPerPage,
          offset: clear ? 0 : this.items.length,
          context: (!!this.search && this.search.length > 1) ? this.search : undefined,
          sorting: this.settings.sorting || 'name%1',
          q: this.getQ(),
        };
        let queryString = getQuery(params);
        if (this.settings?.extraController?.getListExtraQuery) {
          queryString += `&${this.settings.extraController.getListExtraQuery()}`;
        }
        if (this.settings?.list_url_suffix) {
          queryString += `&${this.settings.list_url_suffix}`;
        }
        const result = await this.$eva.$http.getList(`${url}?${queryString}`);
        if (!result) {
          throw new Error ('Incorrect list result');
        }
        const { items, total } = result;
        if (clear) {
          this.items = [];
          this.total = null;
        }
        if (this.settings.onLoad) {
          this.settings.onLoad(items);
        }
        this.items = (this.items || []).concat(items);
        this.total = total;
      } catch (error) {
        this.loadItemsError = 'Ошибка при загрузке';
        console.error(error.message);
      } finally {
        this.loading = false;
      }
    },
    async onIntersect(entries) {
      if (!this.loading && !this.loadItemsError && entries[0].isIntersecting) {
        await this.loadItems();
      }
    },

    setScrollHandler() {
      if (!this.scrollEventHandler) {
        this.scrollEventHandler = () => {
          if (this.shownMenu) {
            this.$refs.menu.onResize();
          }
        }
        window.addEventListener('scroll', this.scrollEventHandler, true);
      }
    },
    clearScrollHandler() {
      if (this.scrollEventHandler) {
        window.removeEventListener('scroll', this.scrollEventHandler, true);
      }
    },
    confirm() {
      this.modelValue = this.copyModelValue;
      this.shownMenu = false;
    },
    close() {
      this.shownMenu = false;
    }
  },

  created() {
    // todo: возвращается пустой объект в случае, если ref_ не указывался.
    // Делаю специальную очистку, но это неправильно, должен возвращаться null для корректной работы
    // Обратиться к разработчикам сервера
    if (!this.multiple && this.modelValue && !this.modelValue.id) {
      //this.clearModel();
    }

    this.settings?.extraController?.setContext(this);
    this.clearAndSearchItems = debounce(async (page) => {
      await this.loadItems(true);
    }, DEBOUNCE);

    this.getQ();
  },

  beforeDestroy() {
    this.clearScrollHandler();
  }
}
</script>

<style lang="less">
.eva-catalog-ref-field {
  min-width: 0;

  .v-menu {
    .v-menu__content {
      max-width: 100%!important;
    }
  }

  .eva-textbox__input {
    height: unset!important;
    min-height: 100%;
    padding: 3px 0;
    overflow-x: hidden;
  }
  .eva-textbox__input-inner {
    .eva-catalog-ref-field__list {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      .eva-catalog-ref-field__chip {
        background-color: #0260CF !important;
        color: white !important;
        white-space: normal;
        height: auto;
        min-height: 24px;
        margin: 0!important;
        .v-chip__close {
          margin: 0 !important;
        }
        .v-chip__content {
          gap: .75rem;
          width: 100%;
        }
        .v-icon {
          font-size: 16px !important;
        }
      }

      .eva-catalog-ref-field__chip-error {
        border: 2px solid #CC1D26 !important;
      }
    }
    &.eva-textbox__input-inner--no-wrap {
      .eva-catalog-ref-field__list {
        flex-wrap: nowrap;
        .v-chip {
          overflow: unset;
        }
      }
    }
    &.eva-textbox__input-inner--column {
      .eva-catalog-ref-field__list {
        flex-direction: column;
      }
    }
  }

  .eva-catalog-ref-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    padding: @eva-padding;
  }

  .eva-catalog-ref__list-item {
    padding-left: @eva-padding * 2;
    padding-top: (@eva-padding / 2);
    padding-bottom: (@eva-padding / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .v-icon {
      font-size: 16px!important;
    }
    &:hover {
      background-color: #263444;
    }
  }

  .eva-catalog-ref__list-item__active {
    background-color: #d2d8dd!important;
  }

  .eva-catalog-ref__list-item__text-icon {
    margin-left: 10px;
  }

  .eva-catalog-ref__list-item__text-no-icon {
    margin-left: 26px;
  }

  .eva-catalog-ref__list-item__actions {
    display: flex;
    flex-direction: row;
    gap: (@eva-padding / 1);
    padding-right: @eva-padding;
  }

  .eva-catalog-ref-list-footer {
    display: flex;
    justify-content: center;
  }

  .v-select__selections {
    input {
      display: none;
    }
  }
}
</style>
