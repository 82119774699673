<template>
  <div class="eva-chip" :class="classes">

    <div class="prewiev-img-background" v-if="isImageAvailable(tag) && preview && checkImg(tag)"
      @click="showImageDialog(tag)">
      <img class="prewiev-img" :src="getImageUrl(tag)" @error="handleImageError(tag)" />
    </div>

    <div class="eva-chip__inner" :style="style" @click.stop.prevent="onClick($event)" @mousedown.stop.prevent>

      <div class="eva-chip__text eva-text-clipped eva-foreground-1">
        {{ label }}
      </div>

      <div v-if="commands" class="eva-chip__buttons">
        <div v-for="(item, index) in commands" :key="index" class="eva-chip__button mdi eva-foreground-1"
          :class="item.icon" @click.stop.prevent="runCommand(item)" @mousedown.stop.prevent></div>
      </div>
    </div>

  </div>
</template>

<script>
import EvaFileAttachFieldPreviewImageDialog from '../../../../metadatas/components/fields/files/fileAttachField/EvaFileAttachFieldPreviewImageDialog.vue';

export default {
  name: "eva-chip",

  props: {
    label: {},
    commands: {},
    tag: {},
    preview: {
      type: Boolean,
      default: false,
    },
    isFlat: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
    color: {
      type: String,
      default: null,
    },
  },

  components: {
    EvaFileAttachFieldPreviewImageDialog,
  },

  data() {
    return {
      imageError: {},
    };
  },

  computed: {
    hasClickListener() {
      return !!(this.$listeners && this.$listeners.click);
    },

    classes() {
      return {
        "eva-cursor-pointer": this.hasClickListener,
        "eva-chip--flat": this.isFlat,
        "eva-chip--small": this.size === "small",
        "eva-chip--medium": this.size === "medium",
        "eva-chip--large": this.size === "large",
      };
    },
    style() {
      let baseStyle = "";

      if (this.color) {
        baseStyle += `background-color: ${this.color}`;
      }

      return baseStyle;
    },
  },

  methods: {
    onClick(e) {
      if (!this.hasClickListener) {
        return;
      }
      this.$emit("click", e);
    },
    runCommand(item) {
      item.handler(this.tag);
    },

    getImageUrl(item) {
      return `/api/v1/fileservice/fileobject/preview_image/${item.id}?target_width=50&target_height=50`;
    },

    isImageAvailable(item) {
      return this.getImageUrl(item) && !this.imageError[item.id];
    },

    handleImageError(item) {
      this.$set(this.imageError, item.id, true);
    },

    async showImageDialog(item) {

      const { data } = await this.$eva.$http.getBlob(`/api/v1/fileservice/file/${item.id}`, 'GET');
      const file = data;
      await this.$eva.$boxes.show({
        type: "dialog",
        header: file.name,
        component: EvaFileAttachFieldPreviewImageDialog,
        componentProps: { image: file, box: this },
      });
    },

    checkImg(item) {
      //проверка на то что это картинка
      const extensions = {
        imageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'ico', 'webp', 'svg', 'jfif'],
      };
      const extension = getFileExtension(item.name, item.extension);

      function getFileExtension(filename, extension) {

        if (!!extension) {
          return extension.split('.').pop().toLowerCase();
        } else {
          const extensionMatch = filename.match(/\.([^.]+)$/);
          return extensionMatch ? extensionMatch[1].toLowerCase() : '';
        }
      }

      for (const item in extensions) {
        if (extensions[item].includes(extension)) {
          return getIconsExtension(item);
        }
      }

      function getIconsExtension(extensionFiend) {
        switch (extensionFiend) {
          case 'imageExtensions':
            item.vTooltip = 'Картинка';
            return true

        }
      }

      return false
    },



  },
};
</script>

<style lang="less">
.eva-chip {
  display: flex;
  flex-direction: row;
  align-items: center;


  .eva-chip__text {
    margin-left: 10px;
  }

  &:hover {
    .eva-chip__text {
      color: #3e4c5d !important;

    }

    .eva-chip__button {
      color: #3e4c5d !important;
    }
  }

  .prewiev-img-background {
    border: 1px solid #495a6f;
    border-radius: 5px;
    width: 52px;
    height: 52px;
  }

  .prewiev-img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 5px;
  }
}
</style>
