import evaApp from "@eva/client/app";

async function checkLicense(videoStreamId) {
  try {
    let result = false;
    if (videoStreamId) {
      const videoStream = await evaApp.$http.get(
        `/api/v1/videohubservice/videostream/${videoStreamId}`
      );
      const provider_details = videoStream?.provider_details;
      result = evaApp.$licenses.checkLicenses(provider_details.vendor_code);
    }
    return result;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export default checkLicense;
