<template>
  <div class="eva-video__header">
    <div class="eva-video__header-left">
      <v-menu
        :disabled="settings.disabled || disabled"
        v-if="
          settings.monitoringObjectList && settings.monitoringObjectList.length
        "
        offset-y
        attach
        @input="onShowMOList"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn text outlined v-bind="attrs" v-on="on" color="white">
            <span style="margin: auto">{{
              currentMonitoringObject?.name
            }}</span>
            <v-icon v-if="!settings.disabled && showState && !isFullscreen">
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list dense :style="monitoringObjectListStyle">
          <v-list-item
            v-for="(monitoringObject, index) in settings.monitoringObjectList"
            :key="index"
            @click="onSetMonitoringObject(monitoringObject)"
          >
            <v-list-item-title>{{ monitoringObject.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="eva-video__header-right">
      <eva-btn
        v-if="
          showState &&
          currentMonitoringObject?.status &&
          currentMonitoringObject?.status?.ACT
        "
        icon="mdi-circle"
        type="icon-text--secondary--small"
        label="Rec"
        tooltip="Ведётся запись"
      />
      <eva-btn
        v-if="
          showState &&
          currentMonitoringObject?.status &&
          currentMonitoringObject?.status?.ARM
        "
        class="ml-2"
        icon="mdi-shield-check"
        type="icon--secondary--small"
        tooltip="На охране"
      />
      <eva-video-header-clock v-show="showState" />
      <eva-btn
        v-if="hasBtnRemove"
        class="ml-2"
        icon="mdi-close"
        type="icon--secondary--small"
        @click="onRemoveItemFromPanel"
        tooltip="Убрать с панели"
      />
    </div>
  </div>
</template>
<script>
import EvaVideoHeaderClock from "./EvaVideoHeaderClock.vue";

export default {
  name: "eva-video-mo-header",
  components: {
    EvaVideoHeaderClock,
  },
  props: {
    settings: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFullscreen: {
      type: Boolean,
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentMonitoringObject: {
      type: Object,
    },
    mainContainerHeight: {
      type: Number,
    },
    hasBtnRemoveFromThePanel: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      monitoringObjectList: [],
      monitoringObjectListStyle: null,
    };
  },

  async mounted() {
    this.$set(
      this.$data,
      "monitoringObjectList",
      this.settings.monitoringObjectList
    );
  },

  computed: {
    hasBtnRemove() {
      return this.hasBtnRemoveFromThePanel 
        && !this.isFullscreen 
        && this.settings.tuning;
    }
  },

  methods: {
    onShowMOList(value) {
      if (value) {
        this.monitoringObjectListStyle = {
          maxHeight: `${this.mainContainerHeight - 40}px`,
          overflow: "auto",
        };
      } else {
        this.monitoringObjectListStyle = null;
      }
    },
    onSetMonitoringObject(monitoringObject) {
      this.$emit("setCurrentMO", monitoringObject);
    },
    onRemoveItemFromPanel() {
      this.$emit("removeItemFromPanel");
    },
  },
};
</script>
<style lang="less">
//Полупрозрачный фон для хедера
.eva-video__header-background {
  height: 40px;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
}
.eva-video__header {
  height: 40px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  //Отображение и выборк ОМ (слева)
  .eva-video__header-left {
    max-width: 65%;
    color: white;

    .v-btn {
      max-width: 100%;

      .v-btn__content {
        width: 100%;
        justify-content: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  //Часы и иконки (справа)
  .eva-video__header-right {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: white;
    z-index: 2;
  }
}
</style>
