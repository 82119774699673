import EvaPlugin from "@eva/client/plugins/EvaPlugin";
import EvaLoaderDefault from './components/EvaLoaderDefault.vue';
import EvaLoaderBlueWhite from './components/EvaLoaderBlueWhite.vue';

class LoaderEvaPlagin extends EvaPlugin {
  constructor(app) {
    super(app);
    this.loader = null;
    this.requestCounter = 0;
  }

  install() {
    this.app.components(
      EvaLoaderDefault,
      EvaLoaderBlueWhite
    );
  }

  showLoader(style='eva-loader-default') {
    this.requestCounter += 1;
    if (!this.loader) {
      this.loader = this.app.$tools.appendComponent({
        component: style
      });
    }
  }
  
  hideLoader() {
    this.requestCounter -= 1;
    if (this.requestCounter < 1) {
      this.app.$tools.dismountComponent(this.loader);
      this.loader = null;
    }
  }
}

export default LoaderEvaPlagin;
