import { defineStore } from 'pinia';
import Vue, { ref, computed, reactive } from 'vue';
import evaApp from "@eva/client/app";

export const useTasksPlanningStore = defineStore('tasksPlanning', { 
  state: () => ({
    selectedPlan: ref(null),
    // register - реестр, create - создание, edit - редактирование
    leftPanelMode: ref('registry'),
    detailIsPreview: ref(true),
    hasRejected: ref(false),
    message: ref(null),
    approvedDate: ref(null),
    history: ref([]),
    filter: reactive({
      selectedMonth: ref(new Date()),
      start_time: ref(null),
      end_time: ref(null),
      custom_start_time: ref(null),
      custom_end_time: ref(null),
      period_type: ref('WEEK'),
      period_range: ref(7 * 24 * 3600 * 1000),
      deep: ref(null),
      ref_device_category: ref([]),
      ref_device_type: ref([]),
      context: ref(null),
    }),
    period_types: ref({
      day: {
        name: 'DAY',
        milliseconds: 24 * 3600 * 1000,
      },
      week: {
        name: 'WEEK',
        milliseconds: 7 * 24 * 3600 * 1000,
      },
      month: {
        name: 'MONTH',
        milliseconds: 31 * 24 * 3600 * 1000,
      },
      quarter: {
        name: 'QUARTER',
        milliseconds: 3 * 31 * 24 * 3600 * 1000
      },
      halfYear: {
        name: 'HALF_YEAR',
        milliseconds: 6 * 31 * 24 * 3600 * 1000,
      },
      year: {
        name: 'YEAR',
        milliseconds: 12 * 30 * 24 * 3600 * 1000,
      },
    }),
    filterFields: [
      'custom_start_time',
      'custom_end_time',
      'ref_device_category',
      'ref_device_type',
      'deep',
      'context'
    ],
    highlightedTask: ref(null),
    totalLaborCost: ref(null),
    showChanges: ref(false),
    tree: ref([]),
  }),

  getters: {
    isCustomDate: (state) => {
      return state.filter.custom_start_time > 0;
    },
    getSelectedMonth: (state) => {
      let month = state.filter.selectedMonth.toLocaleString('ru', { month: 'long' });
      return state.isCustomDate
        ? `${evaApp.$tools.formatDate(
            state.filter.custom_start_time,
            "DD.MM.YYYY"
          )} - ${evaApp.$tools.formatDate(
            state.filter.custom_end_time,
            "DD.MM.YYYY"
          )}`
        : `${
            month.charAt(0).toUpperCase() + month.slice(1)
          } ${state.filter.selectedMonth.getUTCFullYear()}`;
    },
  },

  actions: {
    /**
     * Функция для смены отображения левой панели
     * @param {*} mode - тип панели (create - отобразить панель создания, registry - вывести реестр, detail - вывести отображения для детальной)
     */
    changeLeftPanelMode(mode) {
      const route = evaApp.root.$route;
      if (mode === 'registry') {
        history.pushState({}, '', `#${route.path}`);
      } else if (mode === 'detail') {
        history.pushState({}, '', `#${route.path}?work_plan=${this.selectedPlan?.id}`);
      }

      this.leftPanelMode = mode;
    },
  
    /**
     * Функция для проверки текущего режима отображения левой панели
     * @param {*} mode 
     * @returns {Boolean}
     */
    leftPanelModeIsEqual(mode) {
      return this.leftPanelMode === mode;
    },

    changeDetailMode() {
      this.detailIsPreview = !this.detailIsPreview;
    },

    changeRejected() {
      this.hasRejected = !this.hasRejected;
    },

    statusIsEqual(status, plan = this.selectedPlan) {
      return plan?.ref_work_plan_status?.id === status;
    },

    setMessage(message) {
      this.message = message;
    },

    setApprovedDate(date) {
      this.approvedDate = date;
    },
  
    async setSelectedPlanById(planId) {
      this.selectedPlan = await evaApp.$http.getItem(`/api/v1/toir/workplan/${planId}`);
    },

    async openDetail(planId) {
      await this.setSelectedPlanById(planId);
      this.changeLeftPanelMode('detail');
    },

    async loadHistory() {
      try {
        evaApp.$loader.showLoader();
        const { items } = await evaApp.$http.get(`/api/v1/toir/workplan/${this.selectedPlan?.id}/history`);

        if (items.length) {
          this.history = items;

          for (let day of this.history) {
            let dateWasFounded = false;
            for (let event of day?.items) {
              if (event.code === 'ToirWorkPlanEvent:8') {
                this.setApprovedDate(event.time);
                dateWasFounded = true;
                break;
              }
            }

            if (dateWasFounded) {
              break;
            }
          }

          if (
            [
              'ToirWorkPlanEvent:7',
              'ToirWorkPlanEvent:13',
              'ToirWorkPlanEvent:18'
            ].some((code) => items[0].items[0].code === code)
          ) {
            this.setMessage(items[0].items[0]?.message);
            this.changeRejected();
          }
        }
      } catch (error) {
        console.error(error?.response?.data?.usr_msg);
      } finally {
        evaApp.$loader.hideLoader();
      }
    },

    setStartMonthTime(time = new Date()) {
      const date = new Date(time);
      date.setUTCDate(1);
      date.setUTCHours(0,0,0,0);
      this.filter.start_time = date.getTime();
    },
    setEndMonthTime(time = new Date()) {
      const date = new Date(time);
      date.setUTCMonth(date.getUTCMonth() + 1);
      date.setUTCDate(0);
      date.setUTCHours(23,59,59, 999);
      this.filter.end_time = date.getTime();
    },

    changeSelectedMonth(value) {
      const newDate = new Date(this.filter.selectedMonth);
      if (newDate.getMonth() >= 0) {
        newDate.setMonth(newDate.getMonth() + value);
      } else {
        newDate.setFullYear(newDate.getFullYear() + value);
        newDate.setDate(1);
        newDate.setMonth(12)
      }

      this.filter.selectedMonth = newDate;
      this.setStartMonthTime(newDate);
      this.setEndMonthTime(newDate);
    },

    setDefaultMonthValues() {
      let date = new Date();

      if (!(
        date.getTime() >= this.selectedPlan?.start_time
        && date.getTime() <= this.selectedPlan?.end_time
      )) {
        date = new Date(this.selectedPlan?.start_time);
      }

      date.setUTCDate(1);
      date.setUTCHours(0, 0, 0, 0);
      this.filter.selectedMonth = date;
      this.setStartMonthTime(date);
      this.setEndMonthTime(date);
    },

    setContext(value) {
      this.filter.context = value;
      this.updateURLQueries();
    },

    setFilter(value) {
      this.filterFields.forEach((key) => {
        this.filter[key] = value[key];
      });
      this.updateURLQueries();
    },

    updateURLQueries() {
      const params = [];
      const route = evaApp.root.$route;

      this.filterFields.forEach((key) => {
        if (!!this.filter[key]) {
          if (Array.isArray(this.filter[key])) {
            if (this.filter[key]?.length) {
              params.push(
                `${key}=${this.filter[key]
                  .map((item) => item?.id)
                  .join(',')}`
              );
            }
          } else {
            const value = this.filter[key];
            if (key.includes('custom_')) {
              key = key.replace('custom_', '');
            }
  
            params.push(`${key}=${value}`);
          }
        }
      });

      const paramsString = params?.length > 0 ? `&${params.join('&')}` : '';
      history.pushState(
        {},
        '',
        `#${route.path}?work_plan=${this.selectedPlan?.id}${paramsString}`
      );
    },

    removeTreeItems(parent) {
      const childrens = this.tree.filter((node) => node?.parentKey === parent);
      if (childrens.length) {
        for (let children of childrens) {
          this.removeTreeItems(children.uniqueKey);
          const taskIndex = this.tree.findIndex((node) => node.uniqueKey === children.uniqueKey);
          if (taskIndex !== -1) {
            this.tree.splice(taskIndex, 1);
          }
        }
      }

    },

    changeHighlightedTask(task) {
      this.highlightedTask = !this.highlightedTask?.id ? task : null;
    },

    async getTotalLaborCosts() {
      try {
        evaApp.$loader.showLoader();
        const period_start = this.isCustomDate
          ? this.filter.custom_start_time
          : this.filter.start_time;

        const period_end = this.isCustomDate
          ? this.filter.custom_end_time
          : this.filter.end_time;

        const query = `?period_start=${period_start}&period_end=${period_end}&period_range=${this.filter.period_range}&calendar_period_range=${this.filter.period_type}`;
        const { items } = await evaApp.$http.getList(`/api/v1/toir/workplan/${this.selectedPlan?.id}/hierarchy/totalLaborCost${query}`);
        this.totalLaborCost = items;
      } catch(err) {
        console.error(err);
      } finally {
        evaApp.$loader.hideLoader();
      }
    },

    checkIsHovered(task) {
      return task?.uniqueKey === this.highlightedTask?.uniqueKey
        && task?.parentKey === this.highlightedTask?.parentKey;
    },

    changeShowChanges() {
      this.showChanges = !this.showChanges;
    },

    // Построение дерева

    getPeriodQuery(limit = 20, offset = 0) {
      const period_start = this.filter.custom_start_time || this.filter.start_time;
      const period_end = this.filter.custom_end_time || this.filter.end_time;

      return `period_start=${period_start}&period_end=${period_end}&period_range=${this.filter.period_range}&calendar_period_range=${this.filter.period_type}&limit=${limit}&offset=${offset}`;
    },

    getQuery() {
      const queries = [];
      if (this.filter?.ref_device_category?.length) {
        queries.push(`ref_device_category__in__[${this.filter?.ref_device_category.map((category) => `"${category?.id}"`).join(',')}]`);
      }

      if (this.filter?.ref_device_type?.length) {
        queries.push(`ref_device_type__in__[${this.filter?.ref_device_type.map((type) => `"${type?.id}"`).join(',')}]`);
      }

      return queries?.length ? `?q=${queries.join('%26|')}&` : '?';
    },
    /**
     * Основной метод для загрузки элементов дерева
     * @param {*} parent 
     * @param {*} limit 
     * @param {*} offsets 
     */
    async loadTreeNodeChildrens(
      parent = null,
      limit = 20,
      offsets = {
        ref_monitoring_object: 0,
        ref_device_category: 0,
        ref_device_type: 0,
        ref_regulation_card: 0,
        ref_scheduled_work: 0,
      },
      totals = {
        ref_monitoring_object: 0,
        ref_device_category: 0,
        ref_device_type: 0,
        ref_regulation_card: 0,
        ref_scheduled_work: 0,
      }
    ) {
      try {
        const parentType = parent ? parent?.type : 'ref_monitoring_object';
        const isParentDeep = Number.isInteger(this.filter?.deep) && parent?.deep != null
          ? parent.deep >= this.filter.deep
          : false;

        const categoriesUrl = isParentDeep
          ? `/categories/${parent?.ref_device_category?.id}`
          : '';

        let itemType = 'ref_monitoring_object';
        let baseUrl = `/api/v1/toir/workplan/${this.selectedPlan.id}/hierarchy/monitoring_objects`;
        let additionalFields = [];

        if (parent?.id) {
          baseUrl += `/${parent?.ref_monitoring_object?.id}`;
        }

        switch (parentType) {
          case 'ref_device_category': {
            baseUrl += isParentDeep
              ? `${categoriesUrl}/types`
              : `/type`;
            itemType = 'ref_device_type';
            additionalFields = ['ref_monitoring_object', 'ref_device_category'];
            break;
          }
          case 'ref_device_type': {
            baseUrl += isParentDeep
              ? `${categoriesUrl}/types/${parent?.ref_device_type?.id}/regulations`
              : `/regulations`;
            itemType = 'ref_regulation_card';
            additionalFields = ['ref_monitoring_object', 'ref_device_category', 'ref_device_type'];
            break;
          }
          case 'ref_regulation_card': {
            baseUrl += isParentDeep
              ? `${categoriesUrl}/types/${parent?.ref_device_type?.id}/regulations/${parent?.ref_regulation_card?.id}/scheduledWorks`
              : `/regulations/${parent?.ref_regulation_card?.id}/scheduledWorks`;
            itemType = 'ref_scheduled_work';
            additionalFields = ['ref_monitoring_object', 'ref_device_category', 'ref_device_type', 'ref_regulation_card'];
            break;
          }
        }

        if (parent?.id && parentType === 'ref_monitoring_object') {
          let additionalChildrens = null;
          if (isParentDeep) {
            additionalChildrens = await evaApp.$http.get(
              `${baseUrl}/categories${this.getQuery()}${this.getPeriodQuery(limit, offsets.categories)}`
            );
          } else {
            additionalChildrens = await evaApp.$http.get(
              `${baseUrl}/category${this.getQuery()}${this.getPeriodQuery(limit, offsets.categories)}`
            );
          }

          this.addItemsToTree(
            additionalChildrens?.items,
            'ref_device_category',
            parent,
            ['ref_monitoring_object']
          );

          totals.ref_device_category = additionalChildrens?.total;
          if (offsets.ref_device_category < totals.ref_device_category) {
            offsets.ref_device_category += limit;
          }
        }

        const childrens = await evaApp.$http.get(`${baseUrl}${this.getQuery()}${this.getPeriodQuery(limit, offsets[itemType])}`);
        if (childrens?.items?.length > 0) {
          this.addItemsToTree(childrens?.items, itemType, parent, additionalFields);
          
          totals[itemType] = childrens?.total;
          if (offsets[itemType] < totals[itemType]) {
            offsets[itemType] += limit;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    addItemsToTree(items, itemType, parent, additionalFields) {
      if (items.length) {            
        items.forEach((item) => {
          additionalFields.forEach((field) => item[field] = parent[field]);
          item.type = itemType;
          item.name = item.type === 'ref_scheduled_work' ? item?.name : item[item?.type]?.name;
          item.id = item?.id
            ? item?.id
            : item[item?.type]?.id;
          item.uniqueKey = `${item?.type}::${evaApp.$tools.uuid()}`;
          item.parentKey = parent?.uniqueKey || null;
          item.deep = Number.isFinite(parent?.deep) ? parent?.deep + 1 : 1;
        });
        
        if (this.tree.length > 0) {
          const uniqueChildrens = items.filter((child) =>
            !this.tree.some((node) => node?.id === child?.id && node?.parentKey === child?.parentKey)
          );

          if (uniqueChildrens.length > 0) {
            const parentKey = uniqueChildrens[0]?.parentKey;
            const parentIndex = this.tree.findIndex((node) => node?.uniqueKey === parentKey);
            const lastChildrenIndex = this.tree.findLastIndex((node) => {
              return node?.parentKey === parentKey
            });

            if (parentIndex !== -1) {
              const insertPosition = lastChildrenIndex !== -1
                ? lastChildrenIndex + 1
                : parentIndex + 1;

              this.tree.splice(insertPosition, 0, ...uniqueChildrens);
            } else if (this.filter?.context?.length) {
              this.tree.push(...uniqueChildrens);
            }
          }
        } else {
          this.tree.push(...items);
        }
      }
    },

    clearTree() {
      this.tree = [];
    },
    clearLaborCosts() {
      this.totalLaborCost = null;
    },
    async loadAllTasks(limit = 20, offsets, totals) {
      try {
        evaApp.$loader.showLoader();
        const offset = offsets.ref_scheduled_work;
        const queris = {
          ref_work_plan: {
            op: 'eq',
            value: this.selectedPlan?.id,
          },
          name: {
            op: 'regex',
            value: this.filter.context,
          },
        };

        [
          'custom_start_time',
          'custom_end_time',
          'ref_device_category',
          'ref_device_type'
        ].forEach((key) => {
          if (Array.isArray(this.filter[key]) && this.filter[key]?.length) {
            queris[key] = {
              op: 'in',
              value: `[${this.filter[key]
                .map((item) => `"${item?.id}"`)
                .join(',')
              }]`,
            };
          } else if (!Array.isArray(this.filter[key]) && !!this.filter) {
            const field = key === 'custom_start_time'
              ? 'desired_start_work_time'
              : 'desired_end_work_time';

            queris[field] = {
              op: key === 'custom_start_time'
                ? 'gte'
                : 'lte',
              value: this.filter[key]
            }
          }
        });

        const tasks = await evaApp.$http.getList(
          `/api/v1/toir/scheduledwork`,
          {
            limit,
            offset,
            q: queris
          }
        );
        if (this.tree[0]?.type !== 'ref_scheduled_work') {
          this.clearTree();
        }

        totals.ref_scheduled_work = tasks?.total;
        if (tasks?.items?.length) {
          this.addItemsToTree(tasks?.items, 'ref_scheduled_work', null, []);
          
          if (totals.ref_scheduled_work > offsets.ref_scheduled_work) {
            offsets.ref_scheduled_work += 20;
          }
        }
      } catch (error) {
        console.error(error);
        evaApp.$boxes.noftifyError(error?.response?.data?.usr_msg);
      } finally {
        evaApp.$loader.hideLoader();
      }
    },

    getTreeItems(parent = null) {
      return this.tree.filter((node) => node.parentKey === parent);
    },

    // Обновление времени в дереве

    /**
     * 
     * @param {*} source - задание или регламент
     * @param {*} unitFrom - период из которого был перенос
     * @param {*} unitTo - период в который был перенос
     * @param {*} result - результат операции
     * @param {boolean} changeByRegulation - флаг указывающий, что перенос был сделан с помощью регламента 
     */
    updateLaborCosts(source, unitFrom, unitTo, result, changeByRegulation = false) {
      for (let changed of result?.successfully_changed) {
        const itemIndex = this.tree.findIndex((item) => item?.id === changed?.id);
        let oldItem = null;
        if (itemIndex !== -1) {
          oldItem = evaApp.$tools.clone(this.tree[itemIndex]);
          this.tree[itemIndex].desired_start_work_time = changed?.desired_start_work_time;
          this.tree[itemIndex].desired_end_work_time = changed?.desired_end_work_time;
          this.tree[itemIndex].updated_desired_start_work_time = changed?.updated_desired_start_work_time;
          this.tree[itemIndex].updated_desired_end_work_time = changed?.updated_desired_end_work_time;

          source = this.tree[itemIndex];
        }

        const parentKey = source?.type === 'ref_scheduled_work'
          ? source?.parentKey
          : source?.uniqueKey;

        this.updateParentLaborCosts(
          parentKey,
          oldItem,
          changed,
          unitFrom,
          unitTo,
          changeByRegulation,
        );

        this.updateTotalLaborCosts(
          parentKey,
          oldItem,
          changed,
          unitFrom,
          unitTo,
          changeByRegulation
        );
      }
    },

    /**
     * Метод для получения индексов начальных и изменненых периодов
     * @param {*} node - родительский блок задания
     * @param {*} oldTask - копия работы которая была перенесена со старой информацией
     * @param {*} unitFrom - период из которого был перенос
     * @param {*} unitTo - период в который была перенесена
     * @param {boolean} changeByRegulation - флаг указывающий, что перенос был сделан с помощью регламента 
     * @return {{ standartUnitIndex: number, updatedUnitIndex: number }}
     */
    getUnitPeriodIndex(node, oldTask, unitFrom, unitTo, changeByRegulation) {
      let standartUnitIndex = null;
      const updatedUnitIndex = node?.labor_cost?.grouped_labor_costs.findIndex(
        (labor_cost) => labor_cost?.start_period === unitTo?.start_period
          && labor_cost?.end_period === unitTo?.end_period
      );

      if (!changeByRegulation) {
        standartUnitIndex = node?.labor_cost?.grouped_labor_costs.findIndex(
          (labor_cost) => labor_cost?.start_period === unitFrom?.start_period
            && labor_cost?.end_period === unitFrom?.end_period
        );
      } else {
        const haveUpdatedLaborCosts = oldTask?.updated_desired_start_work_time > 0;
        const taskStartPeriod = haveUpdatedLaborCosts
          ? oldTask?.updated_desired_start_work_time
          : oldTask?.desired_start_work_time;

        const taskEndPeriod = haveUpdatedLaborCosts
          ? oldTask?.updated_desired_end_work_time
          : oldTask?.desired_end_work_time;

        standartUnitIndex = node?.labor_cost?.grouped_labor_costs.findIndex(
          (labor_cost) => labor_cost?.start_period <= taskStartPeriod
            && labor_cost?.end_period >= taskEndPeriod
        );
      }

      // standartUnitIndex - индекс периода откуда был перенос
      // updatedUnitIndex- - индекс периода куда был перенос
      return { standartUnitIndex, updatedUnitIndex };
    },

    /**
     * Метод для рекурсивного обновления времени у родительских элементов работ
     * @param {*} parentKey - уникальный ключ родителя задачи (первый элемент всегда будет регламент)
     * @param {*} oldTask - копия работы которая была перенесена со старой информацией
     * @param {*} task - работа которая была перенесена
     * @param {*} unitFrom - период из которого был перенос
     * @param {*} unitTo - период в который была перенесена
     * @param {*} changeByRegulation - флаг обозначающий что перенос был по регламенту
     */
    updateParentLaborCosts(parentKey, oldTask, task, unitFrom, unitTo, changeByRegulation) {
      if (!parentKey) {
        return;
      }

      const parent = this.tree.find((node) => node?.uniqueKey === parentKey);

      if (!!parent) {
        const { standartUnitIndex, updatedUnitIndex } = this.getUnitPeriodIndex(
          parent,
          oldTask,
          unitFrom,
          unitTo,
          changeByRegulation
        );

        parent.labor_cost.updated_grouped_labor_costs[standartUnitIndex].total_cost -= task?.regulation_time;
        parent.labor_cost.updated_grouped_labor_costs[updatedUnitIndex].total_cost += task?.regulation_time;

        this.updateParentLaborCosts(
          parent?.parentKey,
          oldTask,
          task,
          unitFrom,
          unitTo,
          changeByRegulation
        );
      }

    },

    /**
     * Обновление времени для полей линии Всего
     * @param {*} parentKey - уникальный ключ записи в дереве (регламент), нужен просто для получения индексов меняемых полей
     * @param {*} oldTask - копия работы которая была перенесена со старой информацией
     * @param {*} task - работа которая была перенесена
     * @param {*} unitFrom - период из которого был перенос
     * @param {*} unitTo - период в который была перенесена
     * @param {*} changeByRegulation - флаг обозначающий что перенос был по регламенту
     */
    updateTotalLaborCosts(parentKey, oldTask, task, unitFrom, unitTo, changeByRegulation) {
      const parent = this.tree.find((node) => node?.uniqueKey === parentKey);
      
      if (!!parent) {
        const { standartUnitIndex, updatedUnitIndex } = this.getUnitPeriodIndex(
          parent,
          oldTask,
          unitFrom,
          unitTo,
          changeByRegulation
        );

        this.totalLaborCost.updated_grouped_labor_costs[standartUnitIndex].total_cost -= task?.regulation_time;
        this.totalLaborCost.updated_grouped_labor_costs[updatedUnitIndex].total_cost += task?.regulation_time;
      }
    }
  },
});
