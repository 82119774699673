import { defineStore } from 'pinia';

export const accessRoleStore = defineStore({
    id: 'accessRoleStore',
    state: () => ({
        urlSave: ""
    }),
    actions: {
        setUrlSave(url) {
            this.urlSave = url;
            console.log(this.urlSave);
        },
    }
});