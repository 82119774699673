import Vue from "vue";
import EvaRepeaterSource from "../EvaRepeaterSource";
import evaApp from "@eva/client/app";

class EvaRepeaterFieldSource extends EvaRepeaterSource {

  async loadItems(settings, options) {
    let items = settings.model || [];

    if (settings.columns.index) {
      for (let i = options.filter.offset; i < items.length; i++) {
        Vue.set(items[i], 'index', i);
      }
    }

    if (items?.length) {
      items = this.Sort(settings, options, items);
    }
    return {
      items,
      total: items.length
    }
  }

  Sort(settings, options, items) {
    let [field, direction] = options.filter.sorting.split('%');
    direction = direction > 0 ? 'ASC' : 'DESC';
    const path = settings.columns[field]?.path || field;
    return evaApp.$tools.orderBy(items, path, direction);
  }

  async getItem(settings, options, model, index) {
    return this.app.$tools.setDeepDefaults({}, model);
  }

  async addItem(settings, options, model) {
    settings.model.push(model);
  }

  async editItem(settings, options, model, index) {
    Vue.set(settings.model, index ,model);
  }

  async removeItem(settings, options, model, index) {
    settings.model.splice(index, 1);
  }

}

export default EvaRepeaterFieldSource;
