<template>
  <div class="bodyFile">
    <!-- notFiles -->
    <div v-if="!getFiles?.length" class="eva-preview__label eva-foreground-2">
      {{ $eva.$t(`$t.${$options.name}.title.notFile`) }}
    </div>
    <!-- files -->
    <div v-if="labelTitle && dm?.length > 0" class="label eva-textbox__input"> {{ labelTitle }} </div>
    <div v-for="(item, index) in dm" :key="index" class="itemFile">
      <div class="blockItemName">

        <div class="prewiev-img-background" v-if="isImageAvailable(item)" @click="showImageDialog(item)">
          <img class="prewiev-img" :src="getImageUrl(item)" @error="handleImageError(item)" />
        </div>

        <div class="block-download" @click="fileDownload(item)">
          <div class="icons" v-if="!isImageAvailable(item)">
            <span class="mdi" :class="getIcon(item)" v-tooltip="item?.vTooltip"></span>
          </div>
          <div class="nameFile"> {{ getName(item) }} </div>
          <div class="sizeFile"> {{ geSizeFile(item.size) }} </div>
        </div>

      </div>

    </div>

    <!-- loader  -->
    <div class="loader" v-if="loader">
      <div class="progress"></div>
    </div>

  </div>
</template>

<script>
import EvaFileAttachFieldPreviewImageDialog from '../../../metadatas/components/fields/files/fileAttachField/EvaFileAttachFieldPreviewImageDialog.vue';

export default {
  name: 'eva-file-viewer',

  components: {
    EvaFileAttachFieldPreviewImageDialog,
  },

  props: {
    label: {
      type: String,
      default: ''
    },

    dataModel: {
      type: Array,
      default: () => [],
    },
    col: {
      type: Object,
      default: () => { },
    },

  },

  data() {
    return {
      loader: false,
      dm: [],
      labelTitle: '',
      imageError: {}
    }
  },

  computed: {
    getIconFile() {
      return 'mdi-file-document'
    },

    getFiles() {
      if (this.dataModel.length > 0) {
        this.dm = this.dataModel;
        this.labelTitle = this.label;
      } else {
        this.dm = this.col?.files;
        this.labelTitle = this.col?.label;
      }
      return this.dm
    },

  },

  methods: {

    getIcon(item) {

      const extensions = {
        imageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
        docExtensions: ['docx', 'doc'],
        tableExtensions: ['xlsx', 'xls', 'csv'],
        videoExtensions: ['mp4', 'avi', 'mov'],
        pdf: ['pdf']
      };
      const extension = getFileExtension(item.name, item.extension);//item.extension.split('.').pop().toLowerCase();//item.name.split('.').pop().toLowerCase();

      function getFileExtension(filename, extension) {

        if (!!extension) {
          return extension.split('.').pop().toLowerCase();
        } else {
          const extensionMatch = filename.match(/\.([^.]+)$/);
          return extensionMatch ? extensionMatch[1].toLowerCase() : '';
        }
      }


      for (const item in extensions) {
        if (extensions[item].includes(extension)) {
          return getIconsExtension(item);
        }
      }

      function getIconsExtension(extensionFiend) {
        switch (extensionFiend) {
          case 'imageExtensions':
            item.vTooltip = 'Картинка';
            return 'mdi-file-image'
          case 'docExtensions':
            item.vTooltip = 'Документ';
            return 'mdi-file-word'
          case 'tableExtensions':
            item.vTooltip = 'Таблица';
            return 'mdi-file-excel'
          case 'videoExtensions':
            item.vTooltip = 'Видео';
            return 'mdi-file-video'
          case 'pdf':
            item.vTooltip = 'PDF';
            return 'mdi-file-pdf-box'
        }
      }

      return 'mdi-file-document'
    },

    getName(item) {
      const extensionMatch = item.name.match(/\.([^.]+)$/);
      return extensionMatch ? item.name : item.name + item.extension;
    },

    geSizeFile(bytes) {
      const mb = 1024 * 1024,
        gb = 1024 * 1024 * 1024;

      switch (true) {
        case (bytes < 1024):
          return `${bytes} байт`;
        case ((bytes > 1024) && (bytes < mb)):
          return `${(bytes / 1024).toFixed(2)} Кб`;
        case ((bytes > mb) && (bytes < gb)):
          return `${(bytes / mb).toFixed(2)} Мб`;
        case (bytes >= gb):
          return `${(bytes / gb).toFixed(2)} Гб`;
      }
    },

    async fileDownload(item) {

      if (!this.loader) {
        try {
          this.loader = true;
          const urlDownload = item.uploaded_file_id ? item.uploaded_file_id : item.id;
          const { data } = await this.$eva.$http.getBlob(`/api/v1/fileservice/file/${urlDownload}`, 'GET'),
            file = data,
            link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          const checkExtension = item.name.match(/\.([^.]+)$/);
          link.download = checkExtension ? item.name : `${item.name}${item.extension}`;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          link.remove();

        } catch (err) {
          console.error('err, ', err);
          if (err?.response && err.response?.status === 403) {
            this.$eva.$boxes.notifyError('Сессия закрылась, обновите страницу или авторизируйтесь и попробуйте еще раз.');
          } else {
            this.$eva.$boxes.notifyError(`Произошла ошибка, попробуйте еще раз. ${err.response?.data?.usr_msg}`);
          }
        } finally {
          this.loader = false;
        }
      }

    },

    getIdImg(item) {
      return item?.uploaded_file_id ? item?.uploaded_file_id : item.id
    },

    getImageUrl(item) {
      return `/api/v1/fileservice/fileobject/preview_image/${this.getIdImg(item)}?target_width=50&target_height=50`;
    },

    isImageAvailable(item) {
      return this.getImageUrl(this.getIdImg(item)) && !this.imageError[this.getIdImg(item)] && !item.extension.includes('tiff');
    },

    handleImageError(item) {
      this.$set(this.imageError, this.getIdImg(item), true);
    },

    async showImageDialog(item) {
      const urlDownload = item.uploaded_file_id ? item.uploaded_file_id : this.getIdImg(item);
      const { data } = await this.$eva.$http.getBlob(`/api/v1/fileservice/file/${urlDownload}`, 'GET');
      const file = data;
      await this.$eva.$boxes.show({
        type: "dialog",
        header: file.name,
        component: EvaFileAttachFieldPreviewImageDialog,
        componentProps: { image: file },
      });
    },
  }


}
</script>

<style lang="less" scoped>
.bodyFile {
  width: 100%;

  .label {
    /* margin-top: 25px; */
  }

  .itemFile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 5px 0;
    color: #ACBBCB;
    cursor: pointer;

    .blockItemName {
      display: flex;
      align-items: center;
      width: 100%;

      .prewiev-img-background {
        border: 1px solid #495A6F;
        border-radius: 5px;
        width: 52px;
        height: 52px;
      }

      .prewiev-img {
        max-width: 50px;
        max-height: 50px;
        border-radius: 5px;
      }

      .block-download {
        display: flex;
        align-items: center;
        width: calc(100% - 50px);

        .icons {
          font-size: 20px;
          width: 20px;
        }

        .nameFile {
          margin-left: 5px;
          width: calc(100% - 95px);
          word-wrap: break-word;
        }

        .sizeFile {
          display: flex;
          align-items: center;
          color: #7B8B9D;
          white-space: nowrap;
          width: 65px;
        }
      }

    }


  }
}

.loader {
  width: 100%;
  height: 2px;
  /* Серый */
  position: relative;
  overflow: hidden;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #0094FF;
  /* Синий */
  animation: progress 2s linear infinite;
}

@keyframes progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.loaderRW {
  position: absolute;
  background-color: #3e4c5db3;
  width: 100%;
  height: 100%;
  z-index: 10;
}
</style>

<locale lang="ru">
  {
    title:{
      notFile: 'Файлы отсутствуют'
    }
    
  }
</locale>